import * as React from "react";
import * as Article from "@elevio/kb-kit/lib/components/article";
import { useTranslation } from "@elevio/kb-kit/lib/hooks";
import { askArvadaUrl, getHeader } from "./home";
import PageLayout from "../components/layouts/Page";
import Footer from "../components/Footer";
import WithAsideLayout, { Aside, Main, MainHeader, MainBody, } from "../components/layouts/WithAside";
import ArticleList from "../components/ArticleList";
import ArticleMeta from "../components/ArticleMeta";
import ArticleActions from "../components/ArticleActions";
import ArticleFeedback from "../components/ArticleFeedback";
function Page() {
    const { t } = useTranslation();
    return (<PageLayout header={getHeader()} footer={<Footer />}>
      <div className="ask-arvada-logo-wrapper">
        <img className="ask-arvada-logo" src={askArvadaUrl} alt="Ask Arvada"/>
      </div>
      <WithAsideLayout>
        <Main>
          <MainHeader>
            <ArticleActions />
            <h1 className="main-title" data-testid="article-title">
              <Article.Title />
            </h1>
            <ArticleMeta />
          </MainHeader>

          <MainBody>
            <Article.Body />
          </MainBody>
          <ArticleFeedback />
        </Main>

        <Aside>
          <ArticleList isRelated isAside/>
        </Aside>
      </WithAsideLayout>
    </PageLayout>);
}
export default Page;
