import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useArticleFull } from '@elevio/kb-kit/lib/hooks';
import AccessBanner from './AccessBanner';
function ArticleMeta({ className }) {
    const article = useArticleFull();
    if (!article)
        return null;
    return (<>
      <div className={cx('article-meta', className)}>
        <div className="article-meta-item">
          <svg className="article-meta-icon" width="24" height="24" viewBox="0 0 24 24">
            <path d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V12C11 12.3788 11.214 12.725 11.5528 12.8944L15.5528 14.8944C16.0468 15.1414 16.6474 14.9412 16.8944 14.4472C17.1414 13.9532 16.9412 13.3526 16.4472 13.1056L13 11.382V6Z"/>
          </svg>
          <div className="article-meta-label">
            <Trans i18nKey="article.lastUpdated">
              Last updated: {{ relativeUpdated: moment(article.lastUpdatedAt).format('MMM DD, YYYY, h:mm A') }}
            </Trans>
          </div>
        </div>
      </div>
      <AccessBanner />
    </>);
}
export default ArticleMeta;
