import Loader from "./components/Loader";
import ArticlePage from "./pages/article";
import CategoryPage from "./pages/category";
import HomePage from "./pages/home";
import SearchPage from "./pages/search";
import RequestNewPage from "./pages/request";
import Error401Page from "./pages/error-401";
import ErrorPage from "./pages/error";
import SubmitRequest from "./pages/submitRequest";
const config = {
    pages: {
        article: {
            component: ArticlePage,
        },
        home: {
            component: HomePage,
        },
        category: {
            component: CategoryPage,
        },
        search: {
            component: SearchPage,
        },
        error404: {
            component: RequestNewPage,
        },
        error401: {
            component: Error401Page,
        },
        error: {
            component: ErrorPage,
        },
    },
    extraPages: [
        {
            component: SubmitRequest,
            path: 'submitRequest',
        }
    ],
    pageLoadingIndicator: Loader,
};
export default config;
