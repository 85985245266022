const BASE_URL = process.env.COMCATE_API_URL;
const CRM_TOKEN = process.env.CRM_TOKEN;
export const getSubmissions = (token, contactInfo) => {
    const queryParams = new URLSearchParams({ token });
    return fetch(`${process.env.COMCATE_API_URL}/crm/${token}/request-user-submission?${queryParams}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ contactInfo })
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        }
        else {
            throw Error(response.statusText);
        }
    });
};
